// breakpoints

:root {
  --filtersHeight: 35px;

  // colors
  --colorLightGrey: #e5e5e5;
  --colorDarkGrey: #6c757d;
  --colorShadow: #6c757d;

  // layout
  --contentWidth: 90%;
  --maxContentWidth: 1700px;
  --sidePadding: calc((100% - var(--contentWidth)) / 2);

  // movie detail values
  --sizeCreditsDesc: 80%;

  // common variables
  --borderWidth: 1px;
  --borders: var(--borderWidth) solid var(--color-gray, #cbcbcb);
  --transitionsTime: 0.3s;

  // loadin icon
  --loadingIconColor: var(--brandColor);

  // forms
  --formPadding: 20px;
  --formPaddingMobile: 15px;

  // text variables
  --textColor: var(--textColor);
  --textSize: 1rem;
  --textSizeMobile: 1rem;
  --textPadding: 0;
  --textLineHeight: 1.6;
  --textLetterSpacing: 0;

  // inline text
  --textInlineTitleFontSize: var(--textSize);
  --textInlineTitleFontWeiht: bold;
  --textInlineTitleMargin: 0;
  //---------------------
  --titleFontWeight: bold;
  --titleSize: 2rem;
  --titleSizeMobile: 1.5rem;
  --titlePadding: 10px 0;
  --titleColor: var(--brandColor);
  --titleTextTransform: uppercase;
  //---------------------
  --subtitleFontWeight: bold;
  --subtitleSize: var(--textSize);
  --subtitleSizeMobile: var(--textSizeMobile);
  --subtitlePadding: var(--textPadding);
  --subtitleLineHeight: var(--textLineHeight);
  --subtitleColor: var(--textColor);
  --subtitleTextTransform: capitalize;
  // hyperlinks
  --hyperlinkColor: var(--textColor);
  --hyperlinkTextTransform: underline;

  // buttons variables
  --buttonPadding: 7px 15px;
  --buttonPaddingMobile: 5px 12px;
  --buttonFontSizeMobile: var(--textSize);
  --buttonFontSize: var(--textSize);
  --buttonTransition: var(--transitionsTime) ease-in-out;
  --buttonTextTransform: uppercase;
  --buttonMargin: 0;
  --buttonColor: white;
  --buttonBackground: var(--brandColor);
  --buttonBorder: 2px solid transparent;
  --buttonBorderHover: 2px solid var(--brandColor);
  --buttonColorHover: var(--brandColor);
  --buttonBackgroundHover: transparent;
  --buttonFontWeight: bold;

  // messages to user
  --messageColor: var(--brandColor);
  --messageFontSize: var(--subtitleSize);
  --messageFontSizeMobile: var(--subtitleSize);
  --messageTextTransform: uppercase;
  --messageFontWeight: 400;

  // input box
  --inputBoxColor: black;
  --inputBoxIconColor: var(--inputBoxColor);
  --inputBoxBackground: var(--colorLightGrey);
  --inputBoxPadding: 8px;
  --inputBoxPlaceholderColor: var(--colorDarkGrey);
  --inputBoxBorderRadius: var(--borderRadius);
  --inputBoxBorder: var(--borders);
  --inputBoxBorderBottom: initial;
  --inputBoxFontSize: var(--textSize);
  --inputBoxFontSizeMobile: var(--textSizeMobile);

  // homepage
  --marginBetweenComponents: 0;

  // popup custom-page
  --popupCustomHeaderPosition: absolute;
  --popupCustomHeaderTop: initial;
  --popupCustomHeaderRight: 0;
  --popupCustomHeaderBottom: initial;
  --popupCustomHeaderLeft: initial;
  --popupCustomHeaderContent: 0;

  // forms
  --formFieldBorder: unset;

  // movies page
  // filters section
  --filterMovieCastHeight: var(--filtersHeight);
  --filterMovieCastFontSize: initial;
  --filtersFormMarginTop: 3%;
  --filtersFormPadding: var(--formPadding);
  --filtersFormPaddingMobile: var(--formPaddingMobile);
  --filtersFormBackground: var(--secondaryColor);
  --filtersFormBorderRadius: var(--borderRadius);
  --filtersFormBorder: none;
  --filtersHeight: 35px;
  --filtersLabelColor: var(--backgroundColor);
  --filtersLabelTextTransform: uppercase;
  --filtersLabelFontFamily: 'mainfont';
  --filtersLabelFontWeight: bold;
  --filtersLabelFontSize: var(--textSize);
  --filtersLabelFontSizeMobile: var(--textSizeMobile);
  --filtersLabelBackground: var(--brandColor);
  --filtersLabelBorder: 0;
  --filtersLabelBorderRadius: var(--borderRadius) 0 0 var(--borderRadius);
  --filtersLabelAttachedWidth: 150px;
  --filtersLabelAttachedLineHeight: auto;
  --filtersLabelPadding: 3px 0 0 15px;
  --filtersLabelJustifyContent: unset;
  --filtersFormFiltersMargin: 0 0 10px 0;
  --filtersInputFontSize: var(--textSize);
  --filtersInputFontSizeMobile: var(--textSizeMobile);
  --filtersInputBackground: var(--colorLightGrey);
  --filtersInputColor: initial;
  --filtersInputBorder: transparent;
  --filtersInputBorderBottom: initial;
  --filtersInputBorderPosition: 'all';
  --filtersInputBorderRadius: 0 var(--borderRadius) var(--borderRadius) 0;
  --filterInputLocationBackground: var(--filtersInputBackground);
  --filterInputLocationColor: var(--filtersInputColor);
  --filterInputLocationBorder: var(--filtersInputBorder);
  --filterInputLocationBorderBottom: var(--filtersInputBorderBottom);
  --filterInputLocationBorderRadius: var(--filtersInputBorderRadius);
  --filtersTriggerBackgroundColor: var(--brandColor);
  --filtersTriggerIconColor: var(--textColor);

  --filtersMultiselectboxJustifyContent: center;
  --filtersMultiselectboxItemsPerRow: 5;
  --filtersMultiselectboxItemsPerRowMD: 3;
  --filtersMultiselectboxItemsPerRowSM: 2;
  --filtersMultiselectboxGap: 5px;
  --filtersMultiselectboxPadding: 0;
  --filtersMultiselectboxButtonColor: var(--colorDarkGrey);
  --filtersMultiselectboxButtonBackground: transparent;
  --filtersMultiselectboxButtonBorder: none;
  --filtersMultiselectboxButtonBorderColor: transparent;
  --filtersMultiselectboxFontWeight: initial;
  --filtersMultiselectboxTextTransform: initial;

  --filtersMultiselectboxButtonActiveColor: var(--textColor);
  --filtersMultiselectboxButtonActiveBackground: var(--brandColor);
  --filtersMultiselectboxButtonActiveBorderColor: transparent;

  --filtersMultiselectboxButtonHoverColor: var(--textColor);
  --filtersMultiselectboxButtonHoverBackground: transparent;
  --filtersMultiselectboxButtonHoverBorderColor: transparent;

  --filtersCompactedSMFlexFlow: initial;
  --filtersCompactedSMfilterMsgWidth: initial;
  --filtersCompactedSMfilterMsgJustifyContent: initial;
  --filtersCompactedSMfiltersWrapElementsFlex: initial;
  --filtersCompactedSMfiltersWrapElementsMargin: initial;

  --displayMoviesPageTitle: none;
  // More Filters section
  --moreFiltersFontFamily: 'mainfont';
  --moreFiltersFontSize: var(--textSize);
  --moreFiltersFontSizeMobile: var(--textSizeMobile);
  --moreFiltersTextTransform: uppercase;
  --moreFiltersFontWeight: bold;
  --moreFiltersTextColor: var(--brandColor);
  --moreFiltersTogglerColor: var(--brandColor);
  --dropdownInputColor: var(--textColor);
  --dropdownBorder: none;
  --dropdownBorderPosition: all;
  --dropdownBorderRadius: 0;
  --dropdownIconColor: var(--brandColor);
  --dropdownTriggerBackgroundColor: var(--brandColor);
  --dropdownTriggerColor: var(--backgroundColor);
  // more filter form title
  --moreFiltersFormTitleFontSize: var(--subtitleSize);
  --moreFiltersFormPadding: 20px;
  --moreFiltersFormTitleFontSizeMobile: var(--subtitleSizeMobile);
  --moreFiltersFormTitleColor: var(--subtitleColor);
  --moreFiltersFormTitleTextTransform: var(--subtitleTextTransform);
  --moreFiltersFormTitleFontWeight: var(--subtitleFontWeight);
  // more filters form text buttons
  --moreFiltersFormButtonFontSize: var(--textSize);
  --moreFiltersFormButtonFontSizeMobile: var(--textSizeMobile);
  --moreFiltersFormButtonColor: var(--textColor);
  --moreFiltersFormButtonTextTransform: var(--buttonTextTransform);
  --moreFiltersFormButtonFontWeight: var(--buttonFontWeight);
  --moreFiltersFormButtonBackground: transparent;
  --moreFiltersFormButtonBorder: none;
  --moreFiltersFormButtonBorderRadius: var(--borderRadius);
  // more filters form text buttons hover and active effects
  --moreFiltersFormButtonHoverColor: var(--brandColor);
  --moreFiltersFormButtonHoverBackground: transparent;
  --moreFiltersFormButtonHoverBorder: none;
  // Lucky button
  --luckyButtonBackground: #1d1d1b;
  --luckyButtonColor: white;
  --luckyButtonTextTransform: none;
  --luckyButtonFontWeight: normal;
  --luckyButtonFontSize: 12px;
  // load more buutton
  --loadMoreMoviesPadding: 10px 30px;
  --loadMoreMoviesBackgroundColor: var(--brandColor);
  --loadMoreMoviesBorderRadius: var(--borderRadius);
  --loadMoreMoviesBorder: no-border;
  --loadMoreMoviesBorderBotton: var(--loadMoreMoviesBorder);
  --loadMoreMoviesBorderColor: var(--brandColor);
  --loadMoreMoviesColor: white;

  --loadMoreMoviesHoverBackgroundColor: var(--backgroundColor);
  --loadMoreMoviesHoverBorderColor: var(--backgroundColor);
  --loadMoreMoviesHoverColor: var(--brandColor);

  // Movie Cards
  --movieCardItemsPerRow: 2; //only 2 and 3 are supported
  --movieCardPadding: 0;
  --movieCardBorderRadius: var(--borderRadius);
  --movieCardBorder: none;
  --movieCardTitlePosition: left;
  --movieCardTitleFontSize: 18px;
  --movieCardTitleFontSizeMobile: 15px;
  --movieCardTitleTextTransform: capitalize;
  --movieCardTitleFontWeight: bold;
  --movieCardTitleColor: var(--brandColor);
  --movieCardTitlePadding: 18px;
  --movieCardTitleBackground: none;
  --movieCardTitleTextShadow: 2px 2px 4px #000;
  --movieCardFlipPerspective: 700px;
  //--movieCardFlipTransition: transform 0.5s ; deprecated
  --movieCardFrontHoverTransition: scale(1.02);
  --movieCardFrontBoxShadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  --movieCardFrontLegendTextShadow: 2px 2px 4px #000000;
  --movieCardFrontLegendFontFamily: 'mainfont';
  --movieCardFrontLegendWidth: max-content;
  --movieCardFrontLegendWidthMobile: 100%;
  --movieCardFrontLegendPadding: 10px 20px;
  --movieCardFrontLegendPaddingMobile: 10px;
  --movieCardFrontLegendFontSize: 1.5rem;
  --movieCardFrontLegendTextAlign: start;
  --movieCardFrontLegendTextTransform: uppercase;
  --movieCardFrontLegendBackgroundColor: black;
  --movieCardFrontLegendColor: white;
  --movieCardFrontLegendOpacity: 0.7;
  --movieCardFrontLegendFontWeight: bold;

  // movie card back
  // layout
  --movieCardBackBackground: rgba(30, 30, 30, 0.88);
  --movieCardBackPadding: 30px 50px;
  --movieCardBackPaddingMobile: 15px 20px;
  --movieCardBackGridTemplateColumns: repeat(6, 1fr);
  --movieCardBackGridTemplateRows: min-content 40px auto 40px;
  //title
  --movieCardBackTitleSize: 30px;
  --movieCardBackTitleSizeMobile: 25px;
  --movieCardBackTitleLineHeight: 30px;
  --movieCardBackTitleTextTransform: uppercase;
  --movieCardBackTitleFontWeight: bold;
  --movieCardBackTitleColor: var(--brandColor);

  --movieCardReleaseDateBackground: var(--backgroundColor);
  --movieCardReleaseDateFontSize: var(--textSize);
  --movieCardReleaseDatePadding: 0px 10px;
  --movieCardReleaseDateColor: var(--textColor);
  // watch trailer
  --movieCardBackWatchTrailerColor: white;
  --movieCardBackWatchTrailerBackground: none;
  --movieCardBackWatchTrailerMargin: 0;
  --movieCardBackWatchTrailerBorder: 0;
  --movieCardBackWatchTrailerBorderRadius: var(--borderRadius);
  --movieCardBackWatchTrailerFontSize: 20px;
  --movieCardBackWatchTrailerFontSizeMobile: 18px;
  --movieCardBackWatchTrailerLetterSpacing: 0px;
  --movieCardBackWatchTrailerTextTransform: uppercase;
  --movieCardBackWatchTrailerFontWeight: bold;
  --movieCardBackWatchTrailerIconColor: white;
  --movieCardBackWatchTrailerIconBackground: transparent;
  --movieCardBackWatchTrailerIconBorder: 0;
  // streaming logos
  --movieCardBackStreamingLogoWidth: 50px;
  --movieCardBackStreamingLogoWidthMobile: 50px;
  --movieCardBackStreamingLogoMargin: 5px 10px 5px 0;
  // text description
  --movieCardBackTextColor: white;
  --movieCardBackHiperlinksColor: white;
  --movieCardBackHiperlinkTextTransform: none;
  // button
  --movieCardBackButtonPadding: 7px 15px;
  --movieCardBackButtonBackgroundColor: var(--brandColor);
  --movieCardBackButtonBorder: 2px solid;
  --movieCardBackButtonBorderButton: var(--movieCardBackButtonBorder);
  --movieCardBackBorderColor: var(--brandColor);
  --movieCardBackButtonTextTransform: uppercase;
  --movieCardBackButtonColor: white;
  --movieCardBackButtonHoverBackgroundColor: transparent;
  --movieCardBackButtonHoverBorderColor: var(--movieCardBackBorderColor);
  --movieCardBackButtonHoverColor: var(--brandColor);
  // corner widget
  --movieCardCornerTogglerColor: white;
  --movieCardCornerTogglerBackground: var(--brandColor);
  --movieCardCornerTogglerSize: 60px;
  --movieCardCornerTogglerPadding: 10px 10px 0 0;

  // Movie card oneside
  --movieCardOneSideBoxShadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
  --movieCardOneSideTransform: scale(1.05);

  --movieCardNoFlipFontSize: 26px;

  // Movie detail Page
  // layout
  --movieDetailMargin: 30px auto;
  --movieDetailComponentsMargin: 20px 0;

  --movieTitleColor: black;
  --movieTitleFontSize: 35px;
  --movieTitleTextTransform: uppercase;
  --movieTitleLetterSpacing: initial;

  // trailer controls
  --movieDetailPageWatchTrailerDisplay: true;
  --movieDetailPageSeeMoreDisplay: true;
  --movieDetailPageControlsColor: white;
  --movieDetailPageControlsBackground: transparent;
  --movieDetailPageControlsBorder: none;
  --movieDetailPageControlsBorderRadius: none;
  --movieDetailPageControlsHoverColor: white;
  --movieDetailPageControlsHoverBackground: transparent;
  --movieDetailPageControlsHoverBorder: none;
  --movieDetailPageControlsHoverBorderRadius: none;
  --movieDetailPageControlsIconColor: white;
  --movieDetailPageControlsIconBackground: var(--brandColor);
  --movieDetailPageControlsIconBorder: none;
  --movieDetailPageControlsIconBorderRadius: 50px;
  --movieDetailPageControlsIconHoverColor: var(--brandColor);
  --movieDetailPageControlsIconHoverBackground: var(--brandColor);
  --movieDetailPageControlsIconHoverBorder: none;
  --movieDetailPageControlsIconHoverBorderRadius: 50px;
  --movieDetailPageControlsFontWeight: bold;
  // streaming form
  --streamingFormJustifyContent: center;
  --showtimePanelPadding: 0 15px 15px 15px;
  --showtimePanelColor: var(--brandColor);
  --streamingFormBackground: var(--backgroundColor);
  --streamingFormBorder: none;
  --streamingFormBorderRadius: var(--borderRadius);
  --streamingFormPadding: 0;
  --streamingFormPaddingMobile: var(--formPaddingMobile);
  --streamingFormLogoPadding: 15px;
  --streamingFormLogoWidth: 100px;
  --streamingFormLogoBackground: transparent;
  --streamingFormLogoBorder: none;
  --streamingFormLogoFilter: grayscale(1);

  --streamingLogoFallbackColor: var(--brandColor);
  --streamingLogoFallbackBorderColor: grey;

  //tabs
  --selectBtnFontSize: 15px;
  --selectBtnFontSizeMobile: 13px;
  --selectBtnFontWeight: bold;
  --selectBtnTextTransform: uppercase;
  --selectBtnBorderRadius: 0;
  --selectBtnBorder: initial;
  --selectBtnBorderBottom: initial;
  --selectBtnFontSize: 15px;
  --selectBtnPadding: 10px;
  --selectBtnMargin: 0 5px;
  // active
  --selectBtnColorActive: var(--brandColor);
  --selectBtnBackgroundActive: transparent;
  --selectBtnBorderActiveColor: var(--brandColor);
  --selectBtnBorderActive: initial;
  --selectBtnBorderBottomActive: initial;

  // inactive
  --selectBtnColor: var(--brandColor);
  --selectBtnBackground: transparent;
  --selectBtnBorderColor: transparent;

  // showtimes
  --showtimeComponentPadding: 0.5rem;
  --showtimeComponentBackground: var(--brandColor);
  --showtimeHeaderFontSize: 18px;
  --showtimeHeaderFontSizeMobile: 16px;
  --showtimeHeaderTextTransform: uppercase;
  --showtimeHeaderMargin: 5px 0 0 0;
  --showtimeHeaderPadding: 0.5rem;

  --showtimeHeaderInactiveBackground: var(--brandColor);
  --showtimeHeaderInactiveColor: var(--showtimeComponentBackground);
  --showtimeHeaderInactiveBorder: none;

  --showtimeHeaderActiveBackground: white;
  --showtimeHeaderActiveColor: var(--brandColor);
  --showtimeHeaderActiveBorder: none;

  --showtimePanelBackground: transparent;

  // date carousel
  --showtimeDateCarouselFontSize: 12px;
  --showtimeDateCarouselTextTransform: capitalize;
  --showtimeDateCarouselFontWeight: bold;
  --showtimeDateCarouselWidth: 60px;
  --showtimeDateCarouselHeight: 44px;
  --showtimeDateCarouselMargin: 0 0 8px 0;
  --showtimeDateCarouselBorder: 0;
  --showtimeDateCarouselBorderBottom: 0;
  --showtimeDateCarouselBorderBottomRadius: var(--borderRadius);
  --showtimeDateCarouselPadding: 5px 0;

  --showtimeDateCarouselActiveColor: var(--textColor);
  --showtimeDateCarouselActiveBackground: var(--brandColor);
  --showtimeDateCarouselActiveBorder: 0;
  --showtimeDateCarouselActiveBorderBottom: 0;
  --showtimeDateCarouselActiveBorderRadius: var(--borderRadius);
  --showtimeDateCarouselActiveFontWeight: bold;

  --showtimeDateCarouselInactiveColor: var(--brandColor);
  --showtimeDateCarouselInactiveBackground: var(--textColor);
  --showtimeDateCarouselInactiveBorder: 0;
  --showtimeDateCarouselInactiveBorderRadius: var(--borderRadius);
  --showtimeDateCarouselInactiveFontWeight: bold;

  --showtimeHourCarouselActiveColor: var(--showtimeDateCarouselActiveColor);
  --showtimeHourCarouselActiveBackground: var(--brandColor);
  --showtimeHourCarouselActiveBorder: 0;
  --showtimeHourCarouselActiveBorderBottom: 0;
  --showtimeHourCarouselActiveBorderRadius: var(--borderRadius);
  --showtimeHourCarouselActiveFontWeight: bold;

  --showtimeHourCarouselInactiveColor: var(--brandColor);
  --showtimeHourCarouselInactiveBackground: var(--textColor);
  --showtimeHourCarouselInactiveBorder: 0;
  --showtimeHourCarouselInactiveBorderBottom: 0;
  --showtimeHourCarouselInactiveBorderRadius: var(--borderRadius);
  --showtimeHourCarouselInactiveFontWeight: bold;

  --showtimeDateCarouselArrowsColor: var(--colorDarkGrey);

  --showtimeCinemaNameColorActive: var(--backgroundColor);
  --showtimeCinemaNameColor: var(--backgroundColor);
  --showtimeCinemaNameTextTransform: uppercase;
  --showtimeCinemaNameFontSizeMobile: 12px;
  --showtimeCinemaNameFontSize: 18px;

  // showtime cinema address
  --showtimeCinemaAddressFontSize: 16px;
  --showtimeCinemaAddressFontSizeMobile: 16px;
  --showtimeCinemaAddressFontWeight: lighter;
  --showtimeCinemaAddressTextTransform: uppercase;

  --showtimeCinemaAddressSeeMapTextTransform: capitalize;
  --showtimeCinemaAddressSeeMapColor: var(--brandColor);
  --showtimeCinemaAddressSeeMapArrowColor: var(--brandColor);
  --showtimeCinemaAddressSeeMapHoverColor: var(--brandColor);
  --showtimeCinemaAddressSeeMapHoverTextDecoration: underline;

  // showtime controlBtn
  --showtimeControlButtonWidth: 100%;
  --showtimeControlButtonPadding: 10px;
  --showtimeControlButtonBorderRadius: var(--borderRadius);
  --showtimeControlButtonBorder: 0;
  --showtimeControlButtonFontSize: 18px;
  --showtimeControlButtonFontWeigth: initial;
  --showtimeControlButtonTextTransform: uppercase;
  --showtimeControlButtonBackgroundColor: var(--brandColor);
  --showtimeControlButtonColor: var(--showtimeComponentBackground);

  // subscribe component
  --subscribeBackground: var(--brandColor);
  --subscribeBorder: 0;
  --subscribeTextColor: white;
  --subscribeButtonColor: var(--subscribeBackground);
  --subscribeButtonBackground: var(--brandColor);
  --subscribeButtonBorder: 2px solid var(--subscribeButtonBackground);
  --subscribeButtonHoverColor: var(--subscribeButtonBackground);
  --subscribeButtonHoverBackground: transparent;
  --subscribeButtonHoverBorder: var(--subscribeButtonBorder);

  // Movie Detail Title
  --movieDetailTitleFontSize: 40px;
  --movieDetailTitleFontSizeMobile: 40px;
  --movieDetailTitleFontWeight: bold;
  --movieDetailTitleTextTransform: uppercase;
  --movieDetailTitleColor: var(--brandColor);
  --movieDetailTitleMargin: 0;
  --movieDetailTitleLineHeight: 40px;

  // Auth component
  --authMaxWidth: 500px;
  --authWidth: 90%;
  --authMargin: 50px auto;
  --authPadding: 50px;
  --authPaddingMobile: 30px;
  --authFormBorder: 2px solid var(--brandColor);
  --authFormBorderRadius: var(--borderRadius);
  --authInputWidth: 100%;
  --authInputMargin: 5px 0;
  --authInputFontSize: var(--inputBoxFontSize);
  --authInputPadding: var(--inputBoxPadding);
  --authInputBackground: var(--filtersInputBackground);
  --authInputBorder: none;
  --authInputColor: var(--inputBoxColor);
  --authInputPlaceholderColor: var(--inputBoxPlaceholderColor);
  --authSocialLoginsMargin: 0 0 20px 0;
  --authSocialLoginsInnerMargin: 5px 0;
  --authSocialLoginsPadding: 8px;
  --authSocialLoginBtnMargin: 10px 0;

  // Channel Slide
  --channelSlideArrowWrapperPadding: 0 40px;
  --channelSlideArrowWrapperWidth: 100%;
  --channelSlideArrowWrapperTop: 50%;
  --channelSlideArrowWrapperJustifyContent: space-between;
  --channelSlideArrowColor: white;

  --channelCarouselElementsBackground: black;
  --channelCarouselControlsPadding: 20px 0;
  --channelCarouselControlsBackground: black;
  --channelCarouselControlsTitleTextAlign: center;
  --channelCarouselControlsButtonsFlexFlow: column;
  --channelCarouselControlsButtonsJustifyContent: center;
  --channelCarouselControlsButtonsMargin: 15px 0 0 0;
  --channelCarouselControlsButtonsMarginInside: 5px;

  // Cokie Consent
  --cokieConsentHeight: 350px;
  --cokieConsentWidth: 550px;
  --cokieConsentDisplay: flex;
  --cokieConsentPosition: fixed;
  --cokieConsentButtom: 0;
  --cokieConsentTextAlign: center;
  --cokieConsentLineHeight: 50px;
  --cokieConsentBackground: var(--brandColor);

  // Language select
  --languageSelectPadding: 3px 20px 3px 5px;
  --languageSelectMaxWidth: 120px;
  --languajeSelectWidth: 120px;
  --languajeSelectBackground: transparent;
  --languajeSelectColor: white;
  --languajeSelectBorder: var(--borders);
  --languajeSelectBorderRadius: var(--borderRadius);

  // Loading indicator
  --loadingIndicatorColor: var(--brandColor);
  --loadingIndicatorWidth: 45px;
  --loadingIndicatorHeight: 45px;

  // Location selector
  --locationSelectorImgWidth: 10px;
  --locationSelectorImgHeight: 10px;
  --locationSelectorInputPadding: 0 0.5rem;

  --locationSelectorIconPosition: relative;
  --locationSelectorIconTop: initial;
  --locationSelectorIconLeft: -40px;
  --locationSelectorTransformation: initial;
  --locationSelectorIconName: 'search';
  --locationSelectorIconColor: initial;
  --locationSelectorIconTextShadow: none;

  --locationSelectorNearMeBackgroundColor: var(--brandColor);
  --locationSelectorNearMeColor: var(--textColor);

  // Modal map
  --modalMapWidth: 100%;
  --modalMapHeight: 50vh;

  // Credits
  --marginBetweenCredits: 0 0 10px;
  --creditSubtitleFontWeight: bold;
  --creditSubtitleTextTransform: inherit;
  --creditSubtitleColor: var(--textColor);

  // Movie Tell Friends
  --tellFrindsIconsBackground: transparent;
  --tellFriendsTextColor: var(--brandColor);
  --tellFriendsIconsColor: var(--brandColor);
  --tellFriendsIconsMargin: 0 8px;
  --tellFrindsIconsPadding: initial;
  --tellFriendsMargin: 15px 0 0 0;
  --tellFrindsIconsFontSize: 15px;

  // Video Thumbnail

  --videoThumbnailTitleFontSize: 35px;
  --videoThumbnailTitleFontSizeMobile: 15px;
  --videoThumbnailTitleLetterSpacing: initial;
  --videoThumbnailTitleFontWeight: bold;
  --videoThumbnailTitleColor: white;
  --videoThumbnailTitleTextTransform: uppercase;

  --videoThumbnailSubtitleMargin: 0px;
  --videoThumbnailSubtitleColor: var(--videoThumbnailTitleColor);
  --videoThumbnailSubtitleFontSize: 35px;
  --videoThumbnailSubtitleFontSizeMobile: 25px;
  --videoThumbnailSubtitleFontWeight: bold;
  --videoThumbnailSubtitleTextTransform: none;
  --videoThumbnailSubtitleLetterSpacing: var(--videoThumbnailTitleLetterSpacing);

  --videoThumbnailPlayPadding: 0px;
  --videoThumbnailPlaySize: 60px;
  --videoThumbnailPlayColor: white;
  --videoThumbnailPlaySpanMarginLeft: 10px;
  --videoThumbnailPlaySpanFontSize: initial;
  --videoThumbnailPlaySpanFontWeight: bold;
  --videoThumbnailPlaySpanColor: white;
  --videoThumbnailPlaySpanWhiteSpace: initial;
  --videoThumbnailPlaySpanTextTransform: initial;
  --videoThumbnailPlaySpanLetterSpacing: 0;

  --videoThumbnailBtnDetailWidth: max-content;
  --videoThumbnailBtnDetailHeight: auto;
  --videoThumbnailBtnDetailMargin: 0px;
  --videoThumbnailBtnDetailPadding: var(--buttonPaddingMobile);
  --videoThumbnailBtnDetailBackgroundColor: var(--backgroundColor);
  --videoThumbnailBtnDetailBorder: var(--buttonBorder);
  --videoThumbnailBtnDetailBorderBottom: var(--buttonBorder);
  --videoThumbnailBtnDetailBorderColor: var(--buttonBorder);
  --videoThumbnailBtnDetailBorderRadius: var(--borderRadius);
  --videoThumbnailBtnDetailColor: var(--brandColor);
  --videoThumbnailBtnDetailTextTransform: var(--buttonTextTransform);
  --videoThumbnailBtnDetailFontWeight: var(--buttonFontWeight);
  --videoThumbnailBtnDetailHoverBackgroundColor: var(--brandColor);
  --videoThumbnailBtnDetailHoverBorder: var(--buttonBorder);
  --videoThumbnailBtnDetailHoverBorderBottom: var(--buttonBorderHover);
  --videoThumbnailBtnDetailHoverColor: var(--backgroundColor);

  --videoThumbnailInnerWrapperFlexDirection: row;
  --videoThumbnailInnerWrapperJustifyContent: space-between;
  --videoThumbnailInnerWrapperAlignItems: flex-end;
  --videoThumbnailInnerWrapperAlignItemsMobile: unset;
  --videoThumbnailControlButtonsGap: 10px;

  --videoThumbnailLoadMoreWrapperPosition: absolute;
  --videoThumbnailLoadMorePosition: absolute;
  --videoThumbnailLoadMoreFlexDirection: row;
  --videoThumbnailLoadMoreGap: 0;
  --videoThumbnailLoadMoreIconColor: var(--backgroundColor);

  // Video Thumbnail on page content sliders
  --videoThumbnailPageContentSliderFontSize: 15px;

  // Festivals Movie Detail
  --festivalItemBackground: var(--backgroundColor);
  --festivalItemPadding: 15px 35px;
  --festivalItemColor: var(--textColor);
  --festivalItemMapIconColor: var(--brandColor);
  --festivalItemMapFontSize: 15px;
  --festivalItemBorder: none;
  --festivalItemBorderRadius: var(--borderRadius);

  --BrandedMenuLinkColor: black;

  // Competitions
  --competitionMovieDetailBannerHeight: 60px;
  --competitionsTextColor: var(--textColor);
  --competitionsTitleSize: var(--titleSize);
  --competitionsTitleSizeMobile: var(--titleSizeMobile);
  --competitionsTitleColor: var(--textColor);
  --competitionsTitleFontWeight: var(--titleFontWeight);
  --competitionsQuestionMargin: initial;
  --competitionsQuestionMarginMobile: var(--competitionsQuestionMargin);
  --competitionsQuestionTitleSize: 24px;
  --competitionsQuestionSizeMobile: 25px;
  --competitionsQuestionTextAlign: center;
  --competitionsQuestionFontWeight: var(--titleFontWeight);
  --competitionsTitleTextTransform: uppercase;
  --competitionsAnswerBoxWidthMobile: initial;
  --competitionsAnswerWidth: 100px;
  --competitionsAnswerWidthMobile: var(--competitionsAnswerWidth);
  --competitionsAnswerWidthBig: var(--competitionsAnswerWidth);
  --competitionsAnswerHeight: 100px;
  --competitionsAnswerHeightMobile: var(--competitionsAnswerHeight);
  --competitionsAnswerObjectFit: content;
  --competitionsAnswerShadow: initial;
  --competitionsAnswerTextSize: initial;
  --competitionsAnswerTextSizeMobile: var(--competitionsAnswerTextSize);
  --competitionsTermsLabelSize: 12px;
  --competitionsMargin: 30px 0 50px;
  --competitionsContentRulesMargin: 150px 0;
  --competitionsContentRulesMarginMobile: 50px 30px 30px 30px;
  --competitionsFormsPading: initial;
  --competitionsFormsBackground: var(--brandColor);
  --competitionsFormsColor: var(--textColor);
  --competitionsFormsSubmitBackground: initial;
  --competitionsFormsSubmitColor: initial;
  --competitionsMovieDetailBtnBackground: black;
  --competitionsMovieDetailBtnTextColor: white;
  --competitionsMovieDetailBtnTextHighlightColor: var(--brandColor);
  --competitionsMovieDetailBtnBorder: none;

  // Social Feed
  --socialFeedElemBackground: white;
  --socialFeedTextColor: black;
  --socialFeedReadMoreColor: #08c;
  --socialFeedTitleColor: black;
  --socialFeedBoxShadow: 0 0 10px 0 rgb(10 10 10 / 20%);
  --socialFeedBoxShadowHover: 0 0 20px 0 rgb(10 10 10 / 40%);
  --socialFeedPadding: 15px;
  --socialFeedBorderRadius: 0;
  --socialFeedBorder: none;
  --socialFeedDateColor: var(--colorDarkGrey);

  // Social Links - header or footer
  --socialLinksColor: var(--brandColor);

  // Carousel
  --carouselMarginBottom: 0px;

  --carouselHeaderFlexDirectionMobile: initial;
  --carouselHeaderTitleFontSizeMobile: 25px;
  --carouselHeaderTitleFontSize: 30px;
  --carouselHeaderTitleTextTransform: var(--titleTextTransform);
  --carouselHeaderTitleLetterSpacing: 0px;
  --carouselHeaderTitleColor: var(--titleColor);

  --carouselWidth: var(--contentWidth);
  --carouselBackground: transparent;
  --carouselPadding: 0;
  --carouselMargin: 20px 0;
  --carouselCardTransformOnHover: scale(1.1);
  --carouselTitleFontSize: 20px;
  --carouselTitleFontSizeMobile: 15px;
  --carouselTitleColor: var(--brandColor);
  --carouselTitleTransform: uppercase;
  --carouselTitleFontWeight: normal;
  --carouselInnerTextColor: white;
  --carouselInnerTextFontSize: 15px;
  --carouselInnerTextFontSizeMobile: 12px;
  --carouselInnerTextBackground: none;
  --carouselInnerTextShadow: none;
  --carouselInnerCardShadowGradient: false;

  // arrows
  --arrowColor: var(--brandColor);
  --arrowBackground: transparent;
  --arrowHoverColor: var(--brandColor);
  --arrowHoverBackground: transparent;
  --carouselControlsArrowTop: 50%;
  --carouselControlsArrowWidth: 30px;
  --carouselControlsArrowHeight: 30px;
  --carouselControlsArrowTransform: translateY(-50%) scale(0.9);
  --carouselControlsArrowHoverTransform: translateY(-50%) scale(1);
  --carouselControlsArrowGap: 0px;
  --carouselControlsArrowBorder: none;
  --carouselControlsArrowColor: var(--brandColor);

  --carouselSlideFooterPadding: 10px 0 0 0;
  --carouselSlideFooterTextAlign: center;
  --carouselTitleFooterTitleFontSize: initial;
  --carouselTitleFooterTitleTextTransform: initial;
  --carouselTitleFooterFontWeight: initial;
  --carouselTitleFooterColor: black;

  --carouselDotsDisplay: flex;
  --carouselDotsBottom: 0;
  --carouselDotBackgroundColor: #181818;
  --carouselDotActiveBackgroundColor: var(--brandColor);
  --carouselDotBorder: 1px solid #dddddd;
  --carouselDotActiveBorder: 1px solid #dddddd;
  --carouselDotOpacity: 0.5;
  --carouselActiveBackgroundColor: white;
  --carouselActiveBorder: var(--carouselDotBorder);
  --carouselDotHoverBackgroundColor: rgba($color: #dddddd, $alpha: 0.5);
  --carouselHoverBorder: var(--carouselDotBorder);
  --carouselDotHoverBorder: var(--carouselDotBorder);

  --carouselChannelArrowHeight: 100%;
  --carouselChannelArrowImgHeight: initial;
  --carouselChannelArrowTop: 50%;
  --carouselChannelArrowTransform: translateY(-50%);
  --carouselChannelArrowLeft: 0;
  --carouselChannelArrowRight: 0;
  --carouselChannelArrowHeightMobile: 100%;
  --carouselChannelArrowImgHeightMobile: initial;
  --carouselChannelArrowTopMobile: 50%;
  --carouselChannelArrowTransformMobile: translateY(-50%);
  --carouselChannelArrowLeftMobile: 0;
  --carouselChannelArrowRightMobile: 0;
  --carouselChannelArrowLeftContentPage: 0;
  --carouselChannelArrowRightContentPage: 0;
  --carouselChannelArrowFilter: unset;

  --carouselViewAllWidth: max-content;
  --carouselViewAllHeight: auto;
  --carouselViewAllMargin: 0px;
  --carouselViewAllPadding: 7px 15px;
  --carouselViewAllBackgroundColor: var(--brandColor);
  --carouselViewAllBorder: var(--buttonBorder);
  --carouselViewAllBorderBottom: var(--carouselViewAllBorder);
  --carouselViewAllBorderRadius: var(--borderRadius);
  --carouselViewAllColor: white;
  --carouselViewAllTextTransform: var(--buttonTextTransform);
  --carouselViewAllFontWeight: var(--buttonFontWeight);
  --carouselViewAllHoverBackgroundColor: var(--buttonBackgroundHover);
  --carouselViewAllHoverBorder: var(--buttonBorderHover);
  --carouselViewAllHoverBorderBottom: var(--buttonBorderHover);
  --carouselViewAllHoverColor: var(--brandColor);
  --carouselViewAllMarginMobile: var(--carouselViewAllMargin);

  // Movie Detail

  // Uplayer
  --uplayAvailabilityOptionBoxShadow: 1px 3px 6px #00000029;
  --uplayAvailabilitySelectedBackground: var(--brandColor);
  --uplayAvailabilityBackground: white;
  --uplayAvailabilityTextColor: black;
  --uplayAvailabilitySelectedTextColor: white;

  // Powered by usheru logo
  --usheruPoweredByTextColor: #5c5c5c;
  --usheruLogoTextColor: #919191;
  --usheruLogoMargin: 0 auto;

  //movieDetail
  --movieDetailVideoThumbnailInnerWrapperFlexDirection: var(--videoThumbnailInnerWrapperFlexDirection);
  --movieDetailVideoThumbnailInnerWrapperJustifyContent: var(--videoThumbnailInnerWrapperJustifyContent);
  --movieDetailVideoThumbnailInnerWrapperAlignItems: var(--videoThumbnailInnerWrapperAlignItems);
  --movieDetailVideoThumbnailInnerWrapperAlignItemsMobile: unset;
  --movieDetailMTilteOutsideDisplay: none;
  --movieDetailMTilteInsideDisplay: none;
  --movieDetailComponentsMarginBottom: 0px;
  --movieDetailTitlesPadding: 10px 0;
  --movieDetailTitlesColor: var(--brandColor);

  // lists
  --infoListPadding: 0;
  --infoListItemMarginFlexDirection: column;
  --infoListItemMargin: 0 0 10px 0;
  --inforListSubtitleMinWidth: auto;
  --infoListStyle: none;
  --infoListSemiColonDisplay: inline;
  --infoListCommanDisplay: inline;

  // Search Movie Component

  --searchMovieComponentWidth: var(--contentWidth);
  --searchMovieComponentMaxWidth: $xxl;
  --searchMovieComponentMargin: 40px auto;
}
