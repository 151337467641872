@import './theme.css';
// @import './styles.primeng.global.scss';
@import './mixins/media-query.mixins.scss';
@import '../../node_modules/primeicons/primeicons.css';
@import './primeng.css';
:root {
  --ease1: cubic-bezier(0.246, 0.75, 0.187, 1);
  --ease2: cubic-bezier(0.19, 1, 0.22, 1);
  --ease3: cubic-bezier(1, 0, 0, 1);
}
body {
  overflow-x: hidden;

  //@include scrollbars();

  * {
    outline: none;
  }

  button {
    border: none;
    background: none;
  }
}

button {
  font-family: inherit;
}

// ============== font ==================
p,
.text {
  line-height: 1.7;
}

.mainfontbold {
  font-weight: bold;
}

.mainfont {
}

.title {
  margin-bottom: 0;
  color: var(--brandColor);
  font-weight: bold;
  font-size: 30px;
  text-transform: uppercase;
}
ush-authentication-modal {
  .title {
    color: var(--textColor);
  }
}

.subtitle {
  margin-bottom: 0;
  color: var(--brandColor);
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
}
// selector component fontstyle
.p-selectbutton .p-button span {
}
.p-col,
.p-col-12 {
  padding: 0;
}
.tab-msg {
  text-align: center;
}

// ========== Page Layout ===========

.page-content {
  margin: 0 auto;
  width: var(--contentWidth);
  max-width: $xl;
}

// modify buttons styles here
.p-button {
  font-weight: bold;
  text-transform: uppercase;
}

.movie-credits {
  .title {
    font-weight: bold !important;
  }
  .directors-content,
  .actors-content {
    margin-top: 5px;
  }
}

input {
  border: none;
  width: 100%;
}

.hide {
  display: none;
}

.brandColor {
  color: var(--brandColor);
}

.backgroundColor {
  color: var(--backgroundColor);
}

.textColor {
  color: var(--textColor);
}

.brandBg {
  background-color: var(--brandColor);
}

.backgroundBg {
  background-color: var(--backgroundColor);
}

.textBg {
  background-color: var(--textColor);
}

.bold {
  font-weight: bold;
}

.fit-content {
  min-width: fit-content;
}

.text-danger {
  color: red;
}

app-header {
  z-index: 1;
}

.channel-slide {
  position: relative;
}

input {
  width: 100%;
}

// USER ACTIVITY PAGE
.user-activity-page {
  .arrows {
    display: none;
    column-gap: var(--spacing-md, 10px);
    @media all and (min-width: $md) {
      display: flex;
    }
  }
}

// Arrow
.arrow-long-css {
  position: relative;
  background: black;
  width: 70px;
  height: 2px;

  .left {
    transform: rotate(180deg);
  }
}

.arrow-long-css:after {
  display: block;
  position: absolute;
  right: 8px;
  transform: rotate(45deg);
  margin-top: -3px;
  border-right: 2px solid black;
  height: 25px;
  content: '';
}

.arrow-long-css:before {
  display: block;
  position: absolute;
  right: 8px;
  bottom: 100%;
  transform: rotate(315deg);
  margin-bottom: -5px;
  border-right: 2px solid black;
  height: 25px;
  content: '';
}

.arrow-channel-slide-left,
.arrow-channel-slide-right {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  cursor: pointer;
  width: 90px;
  height: 30px;
}

.arrow-channel-slide-left {
  &:hover,
  &:focus {
    transform: scale(1.2) rotate(180deg);
  }
}

.arrow-channel-slide-right {
  &:hover,
  &:focus {
    transform: scale(1.2);
  }
}

.transparent-background {
  background-color: transparent !important;
}

button.disabled {
  pointer-events: none;
}

.no-pointer {
  pointer-events: none;
}

.chevron-arrow-down {
  display: inline-block;
  position: relative;
  top: -3px;
  transform: rotate(-315deg);
  border-right: 3px solid var(--brandColor-contrast);
  border-bottom: 3px solid var(--brandColor-contrast);
  width: 12px;
  height: 12px;
}

.visible-on-scroll {
  animation: 1s visibleOnScroll;
  transition: 600ms ease-in-out;
}

// Fix for PrimeNG icons
i.pi {
  font-family: 'primeicons' !important;
}

@keyframes visibleOnScroll {
  0% {
    transform: translate(0, 2rem);
    opacity: 0;
  }
  100% {
    transform: translate(none);
    opacity: 1;
  }
}

.custom-about {
  min-height: 500px;
}

.two-rows-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// carousel
.carousel__wrapper {
  display: flex;
  grid-template-columns: repeat(2, auto) 1fr repeat(2, auto);
  grid-auto-rows: min-content;
  column-gap: 20px;
  row-gap: 10px;
  flex-direction: column;

  @media all and (min-width: $md) {
    display: grid;
  }
  .carousel__title {
    display: flex;
    column-gap: 20px;
    row-gap: 10px;
    grid-row: 1;
    grid-column: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    max-width: 100%;
  }
  .carousel__title h2,
  h2.carousel__title {
    font-size: var(--font-size-160, 1.6rem);
    text-transform: uppercase;
  }
  .carousel__view-all {
    grid-row: 1;
    grid-column: 2;
    align-self: center;
  }

  .carousel__description {
    grid-row: 2;
    grid-column: 1 / 6;
    margin: 0;
  }

  .carousel__content {
    position: relative;
    grid-row: 3;
    grid-column: 1 / 6;
  }
  .carousel__arrow {
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: var(--color-cream-background, var(--backgroundColor));
    width: 35px;
    height: 35px;
    &[disabled] {
      fa-icon {
        color: var(--textColor) !important;
      }
      &,
      * {
        cursor: auto !important;
      }
    }
    @media all and (min-width: $md) {
      display: flex;
    }
    fa-icon {
      color: var(--textColor);
      font-size: 0.8em;
    }
    &:hover {
      fa-icon {
        color: white;
      }
    }
  }
  .carousel__left-arrow {
    grid-row: var(--carousel-arrow-grid-row, 1);
    grid-column: var(--carousel-arrow-left-grid-column, 4);
  }

  .carousel__right-arrow {
    grid-row: var(--carousel-arrow-grid-row, 1);
    grid-column: var(--carousel-arrow-right-grid-column, 5);
  }

  h2 {
    margin: 0;
  }

  .swiper-button-disabled {
    opacity: 0.2;
  }

  .carousel__content {
    display: block;
  }

  :host ::ng-deep {
    ush-arrow {
      i {
        font-size: 30px;
      }
    }
  }

  .error-msg {
    padding: 30px;
    color: black;
    text-align: center;
  }

  swiper-container::part(scrollbar) {
    position: relative;
    margin-top: 10px;
    @media all and (min-width: $md) {
      display: none;
    }
  }
}

// forms
.form-group {
  margin: 0;
  a {
    cursor: pointer;
  }
  .form-group {
    margin: var(--spacing-xl, 40px) 0;
  }
  .form-field {
    margin-bottom: var(--spacing-lg, 20px);
    label {
      display: block;
      margin-bottom: var(--spacing-sm, 5px);
      font-size: var(--font-size-080, 0.9rem);
    }
    .p-password {
      width: 100%;
    }
    .p-component:not(.p-dropdown-panel):not(.p-password-panel):not(.ush-near-me-button) {
      background-color: var(--backgroundColor, transparent);
    }

    .p-autocomplete-input {
      border: var(--formFieldBorder);
      border-left: var(--form-field-border-left);
      background-color: var(--backgroundColor, transparent);
    }

    .p-component.p-button {
      transition: 0.4s ease-in-out;
    }

    .p-component.p-button:is(:hover, :focus, :active) {
      background-color: var(--brandColor-contrast);
      color: var(--brandColor);
    }
  }

  .form-field__error {
    display: block;
    margin-top: var(--spacing-sm, 5px);
    margin-bottom: var(--spacing-md, 10px);
    color: var(--color-error, red);
    font-size: var(--font-size-080, 0.9rem);
  }
}

// FORM FIELDS
.form-field {
  label:first-letter {
    text-transform: capitalize;
  }
}

.form-field__mandatory {
  &::after {
    margin: 0 2px;
    content: '*';
    color: red;
  }
}
