.route-movie-love-47,
.route-love-47 {
  .main {
    padding-bottom: 80px;
  }

  $color1: #000000;
  $color1contrast: white;
  $color1hover: #181818;
  $color2: #ffd638;
  $color2contrast: #000000;
  $color2hover: #ffd638;
  $color3: white;
  $color3contrast: #41471a;
  $color3hover: #f4f4f4;
  $color4: #292929;
  $color4contrast: black;
  $color4hover: white; // #2f2f2f;

  ush-movie-template-b *,
  art-footer * {
    font-family: 'love-47' !important;
    --brandColor: #{$color1};
    --movieHero-fade--color: #{$color1};
    --brandColor-contrast: #{$color1contrast};
    --brandColor-hover: #{$color1hover};
    --secondaryColor: #{color4};
    --secondaryColor-contrast: #{$color4contrast};
    --secondaryColor-hover: #{$color4hover};
    --backgroundColor: #{$color2};
    --backgroundColor-contrast: #{$color2contrast};
    --backgroundColor-hover: #{$color2hover};
    --textColor: #{$color3};
    --textColor-contrast: #{$color3contrast};
    --textColor-hover: #{$color3hover};
  }
  #ush-showtimes-widget-wrapper {
    --componentInCinemas-cinemaHeader-background: #{$color2};
    --componentInCinemas-cinemaHeader-background--hover: #{$color4hover};
    --componentInCinemas-cinemaHeader-textColor: #{$color4contrast};
    --componentInCinemas-cinemaHeader-textColor--hover: #{$color4contrast};
    --componentInCinemas-cinemaHeaderActive-background: #{$color2};
    --componentInCinemas-cinemaHeaderActive-textColor: #{$color2contrast};
    --componentInCinemas-cinemaHeaderActive-background--hover: #{$color4contrast};

    --componentInCinemas-nearMeBackground: #{$color2};
    --componentInCinemas-nearMeBackground--hover: #{$color4hover};
    --componentInCinemas-nearMetextBorder: none;
    --componentInCinemas-nearMeTextColor: #{$color4contrast};

    --locationSelectorIconColor: #{$color2};

    --componentInCinemas-nearestCity-buttonBackground: #{$color4};
    --componentInCinemas-nearestCity-buttonBackground--hover: #{$color4hover};
    --componentInCinemas-nearestCity-buttonTextColor: #{$color4contrast};
    --componentInCinemas-nearestCity-buttonTextColor--hover: #{$color4contrast};
    --componentInCinemas-nearestCity-buttonBackground--active: #{$color2};
    --componentInCinemas-nearestCity-buttonBackground--active--hover: #{$color2hover};
    --componentInCinemas-nearestCity-buttonTextColor--active: #{$color2contrast};
    --componentInCinemas-nearestCity-border: 2px solid transparent;

    --componentInCinemas-dateHighlight-background: #{$color4};
    --componentInCinemas-dateHighlight-textColor: #{$color4contrast};
    --componentInCinemas-dateHighlight-background--hover: #{$color4hover};
    --componentInCinemas-dateHighlight-textColor--hover: #{$color4contrast};
    --componentInCinemas-date-borderBottom: 2px solid #{$color4};
    --componentInCinemas-date-borderBottom--hover: 2px solid #{$color4hover};
    --componentInCinemas-dateHighlight-borderBottom: 2px solid #{$color4};
    --componentInCinemas-dateHighlight-borderBottom--hover: 2px solid #{$color4hover};

    --componentInCinemas-submitBackground: #{$color4};
    --componentInCinemas-submitBackground--hover: #{$color4hover};
    --componentInCinemas-submitTextColor: #{$color4contrast};

    --componentInCinemas-nearestCity-buttonBackground: #{$color4contrast};
    --componentInCinemas-nearestCity-buttonTextColor: #{$color4hover};
    --componentInCinemas-nearestCity-border: 1px solid #{$color2};
    --buttonColorHover: #{$color4contrast};
    --buttonBorderHover: #{$color2};
    --buttonBackgroundHover: #{$color2};
    --componentInCinemas-contentWrapper-background: black !important;
  }

  .rating-list {
    display: none !important;
  }

  .menu-language-selector {
    display: none !important;
  }

  .menu.brandBg ul {
    padding-left: 0 !important;
  }

  #vertical .main {
    background-color: var(--brandColor) !important;
  }

  em {
    color: white !important;
  }

  .video-thumbnail-container {
    .control-buttons {
      align-items: center;
      @include for-tablet-portrait-up {
        position: unset;
        padding-bottom: 5vh;
      }
      @include for-desktop-up {
        position: absolute;
        padding-bottom: 10vh;

        .hero-info {
          margin-left: -50px;
        }
      }
      @include for-big-desktop-up {
        .hero-info {
          margin-left: 0;
        }
      }
    }
    .hero-info {
      > * {
        font-family: 'love-47' !important;
        font-size: 1.6em;

        @include for-tablet-portrait-up {
          font-size: 1.8em;
        }
      }
    }
    .hero-content {
      .background-content {
        z-index: 0;
        position: relative;
      }
    }
    .hero-controls {
      background-color: transparent;
    }
  }

  .video-thumbnail-title {
    display: none !important;
  }

  .control-header .status span {
    color: white !important;
    font-size: 20px !important;
    font-weight: normal !important;
    text-transform: none !important;
    text-shadow: none !important;
  }

  .control-header {
    @media (min-width: 768px) {
      display: flex !important;
      flex-direction: column-reverse !important;
    }
  }

  .control-header .status {
    @media (max-width: 767px) {
      position: absolute !important;
      top: calc(-1 * ((100vw * 9 / 16) / 2)) !important;
    }
  }

  .control-buttons .ctrl-play {
    padding: 14px 20px !important;
    background-color: var(--backgroundColor) !important;
    text-transform: uppercase !important;

    &,
    & > * {
      border: none !important;
      color: black !important;
      background-color: var(--backgroundColor-hover) !important;
    }
    button {
      background-color: transparent !important;
    }
    &:hover {
      border: none !important;
      background-color: white !important;
    }

    button.p-ripple.p-element.p-button.p-component {
    }
  }

  #header-sm.noCollapsed .navbar-toggler fa-icon {
    color: white !important;
  }

  .header-collapse-items {
    box-shadow: 0px 3px 4px #00000029 !important;
  }

  .header-collapse-items:not(.header-movie-menu) {
    a {
      border-color: var(--backgroundColor) !important;
      cursor: pointer !important;
      color: var(--backgroundColor) !important;
    }

    a:not(.social-link):not(.menu-toggler):hover {
      text-decoration: underline !important;
    }

    a.menu-toggler:hover {
      background-color: #444444;
    }
  }

  .header-sm-branded-page {
    flex-direction: row-reverse !important;
  }

  .header-movie-menu .menu-toggler {
    border-color: var(--backgroundColor) !important;
    background-color: var(--backgroundColor) !important;
    cursor: pointer !important;
    color: white !important;

    &:hover,
    &:focus {
      border-color: white !important;
      background-color: white !important;
      color: black !important;
    }
  }

  .header-sm-branded-page .header-movie-menu {
    background-color: var(--brandColor) !important;

    a:not(.menu-toggler) {
      border-color: var(--backgroundColor) !important;
      cursor: pointer !important;
      color: var(--backgroundColor) !important;
    }

    a:not(.social-link):not(.menu-toggler):hover {
      text-decoration: underline !important;
    }

    a.social-link:hover,
    a.menu-toggler:hover {
      background-color: rgba(69, 94, 112);
    }
  }

  ush-footer,
  .footer-wrapper {
    background-color: var(--backgroundColor) !important;
  }

  ush-footer .social-links li.pinterest {
    display: none !important;
  }

  .form-header {
    color: var(--brandColor) !important;
  }

  .header-msg {
    color: var(--textColor) !important;
    font-size: 20px !important;

    @media (min-width: 992px) {
      font-size: 30px !important;
    }
  }

  .movie-credits,
  .more-information {
    li {
      display: flex;
      flex-direction: column !important;
    }
  }

  .video-thumbnail.gradient::after {
    height: 35% !important;
  }

  .scroll-large-video {
    display: none !important;
  }

  .showtime-streaming {
    background-color: transparent !important;
    .p-selectbutton {
      .p-button {
        transform: skew(-10deg);
        margin: 0 !important;
        padding: 5px 20px;
        background-color: transparent !important;
        color: var(--backgroundColor) !important;
        font-size: 18px !important;
        /*font-family: mainfont !important;*/
        border-bottom: none !important;

        @media (min-width: 768px) {
          font-size: 25px !important;
        }

        span {
          transform: skew(10deg);
        }

        &:not(.p-highlight) {
          opacity: 0.5 !important;
          color: var(--textColor) !important;
        }

        &:first-child {
          /*border-right: 2px solid var(--textColor) !important;*/
        }
      }

      #countrySelectWrapper {
        label,
        fa-icon {
          color: white !important;
        }
      }
    }

    .streaming-logo {
      filter: none !important;
      cursor: pointer !important;
    }
  }

  .streaming-logo-fallback {
    color: white !important;
  }

  .panel-content {
    padding-top: 0 !important;
  }

  .ush-at-home-wrapper {
    label,
    .p-dropdown-trigger-icon {
      color: white !important;
      // background-color: var(--backgroundColor) !important;
    }

    .p-dropdown-trigger {
      border-radius: 0 !important;
      background-color: var(--backgroundColor) !important;
    }
  }

  #footer-container ush-showtime-streaming-subscribe .subscribe-form-content {
    .subscribe-title {
      font-weight: normal !important;
      color: white !important;
    }
  }
  .result-msg {
    font-weight: normal !important;
  }

  .header-carousel {
    margin-bottom: 20px !important;
  }

  .trailer img {
    object-position: center !important;
  }

  .header-carousel .caruosel-ctrls {
    .arrow-long {
      background: var(--textColor) !important;

      &::before,
      &::after {
        border-color: var(--textColor) !important;
      }
    }
    .arrow-top {
      --gap-controls: 40px !important;
      --controls-width: 75px !important;

      width: var(--controls-width) !important;

      @media screen and (min-width: 992px) {
        --gap-controls: 80px !important;
        --controls-width: 75px !important;
      }
    }

    .arrow-top-left {
      right: calc(var(--controls-width) + var(--gap-controls, 0px)) !important;
    }

    .arrow-top-right {
      right: 0 !important;
    }
  }

  .arrow-side .pi {
    color: var(--textColor) !important;
  }

  #form-subscrition {
    display: none !important;
  }

  .social-links {
    ul {
      column-gap: 10px;
    }
    fa-icon {
      background-color: transparent !important;
      color: var(--textColor) !important;
    }
  }

  .movie-credits-wrap {
    margin-top: 28px;
    @media screen and (min-width: 992px) {
      margin-top: 58px;
    }
  }

  .production-companies {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid black;
  }

  .subtitle {
    color: var(--textColor) !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
    margin-top: 1px !important;
  }

  .control-header {
    @include for-tablet-landscape-down {
      text-align: center;
      .status {
        margin-top: 0 !important;
        font-size: 16px !important;
      }
    }
  }

  .control-buttons {
    @include for-tablet-landscape-down {
      justify-content: center;
    }
  }

  .showtimes .poster {
    margin-right: 30px;
  }

  .menu-container .menu {
    top: auto !important;
  }

  .menu {
    top: 80px;
    background-color: #f4eddd !important;
  }

  .menu a {
    color: var(--backgroundColor-contrast) !important;
    font-size: 18px !important;
    font-weight: bold !important;
  }

  .menu fa-icon {
    color: white !important;
    font-size: 18px !important;
  }

  .menu .link-box {
    background-color: #{$color4} !important;
    color: #{$color4contrast} !important;
  }

  // .tmdb {
  //   filter: invert(1);
  // }

  .scroll-large-video {
    display: none;
  }

  .status span,
  .ctrl-play span,
  .ctrl-play fa-icon {
    text-transform: uppercase !important;
  }

  .ctrl-play span,
  .ctrl-play fa-icon {
    color: black !important;
    /*font-family: mainfont !important;*/
    font-weight: bold !important;
  }

  .alternative-about-layout-2 {
    @media screen and (min-width: 992px) {
      column-gap: 60px !important;
    }
  }

  .text,
  .subtitle {
    font-size: 1rem !important;
    @media screen and (min-width: 992px) {
      font-size: 1.3rem !important;
    }
  }

  .text {
    margin-top: 0px !important;
  }

  .subscribe-form {
    margin: 20px !important;
  }

  .header-collapse-items.collapsed:not(.header-movie-menu) a span {
    color: white !important;
  }

  .header-collapse-items.collapsed:not(.header-movie-menu) a {
    border-color: white !important;
    color: white !important;
  }

  .header-movie-menu a span {
    color: white !important;
  }

  .header-movie-menu a {
    border-color: white !important;
    color: white !important;
  }

  .controls {
    @include for-tablet-portrait-down {
      position: relative !important;
    }
  }

  .controls.flatAspectRatio {
    bottom: calc(10% + 56.25vw - (100vh)) !important;
  }

  .location-input .p-autocomplete-input {
    background-color: white !important;
  }

  .p-selectbutton > *:not(.p-highlight) {
    color: #b1b1b1 !important;
  }

  .text-danger,
  .result-msg {
    color: white !important;
  }

  .subtitle {
    min-width: 180px !important;
    max-width: 180px !important;
    font-weight: normal !important;
  }

  .text {
    &,
    & span {
      color: var(--textColor) !important;
    }
  }

  .tab-container {
    color: white !important;
  }

  .movie-pic {
    display: flex !important;

    @media (min-width: 992px) {
      display: none !important;
    }
  }

  .movie-detail-container .social-links {
    margin-left: 12px !important;
    column-gap: 15px !important;
  }

  .movie-detail-container .social-links a fa-icon {
    color: var(--textColor) !important;
  }

  .movie-detail-container .share-text {
    font-size: 20px !important;
    font-weight: normal !important;
    text-transform: uppercase !important;
  }

  .more-information .header-msg {
    margin-top: 20px !important;
  }

  .showtime-element > ush-form-subscription {
    display: none !important;
  }
  #vertical {
    .poster {
      img {
        max-height: 70vh !important;
      }
    }
  }
  .more-info {
    .movie-pic {
      display: none;
    }
  }

  .info-list li {
    display: flex !important;
    flex-direction: row !important;
  }

  .footer-wrapper {
    background-color: var(--brandColor) !important;
  }

  .footer-wrapper * {
    color: var(--textColor) !important;
  }

  .menu.brandBg {
    background-color: var(--backgroundColor) !important;
    font-weight: bold !important;
    .link-box {
      border: none !important;
      background-color: #{$color4} !important;
      // color: var(--backgroundColor) !important;
      color: #{$color4contrast} !important;

      &:hover {
        background-color: #{$color4hover} !important;
      }
    }
  }

  .tmdb {
    filter: invert(1) !important;
  }

  .tab-msg,
  .cineamo-header,
  .cineamo-desc {
    color: var(--textColor) !important;
  }

  #mobile-social-links,
  #about ush-movie-tell-friends {
    @include for-tablet-landscape-down {
      .social-link {
        display: none !important;
      }
    }
  }

  #vertical .showtimes .poster {
    display: flex !important;
    justify-content: flex-start !important;

    @include for-tablet-landscape-down {
      display: none !important;
      flex-direction: column !important;
    }
  }

  #vertical .showtimes > * {
    @include for-tablet-landscape-down {
      flex-flow: column-reverse !important;
      align-items: center !important;
    }
  }

  .p-button,
  .button-secondary {
    color: white !important;
  }

  .ush-at-home-wrapper .p-field.attached label {
    // background-color: #{$color4} !important;
    color: #{$color4contrast} !important;
  }

  .ush-at-home-wrapper .p-dropdown-trigger {
    background-color: transparent !important;
  }

  .ush-at-home-wrapper .p-dropdown-trigger-icon {
    border-color: #{$color4} !important;
  }

  .control-header {
    .status {
      position: relative;
      width: 100px;
      text-align: center;
    }
    @media (min-width: 768px) {
      .status {
        position: relative;
        left: 400px;
        width: 100px;
        text-align: center;
      }
    }
    @media (min-width: 1700px) {
      .status {
        position: relative;
        left: 550px;
        width: 150px;
        text-align: center;
      }

      .video-thumbnail-logo {
        width: 600px !important;
      }

      .status span {
        font-size: 30px !important;
      }
    }
  }

  .competitions-button-container {
    background-color: black !important;

    * {
      color: white !important;
    }

    // @media (min-width: 992px) {
    //   position: absolute !important;
    //   width: fit-content !important;
    //   z-index: 1 !important;
    // }
  }

  #vertical:has(.competitions-button-container) .menu.brandBg {
    top: calc(62px + 60px) !important;
  }

  #goToTop {
    background-color: #{$color4contrast} !important;
    color: #{$color4} !important;
  }

  #copyright img {
    filter: drop-shadow(2px 4px 6px #00000057);
  }

  ush-video-thumbnail {
    @media (min-width: 768px) {
      .controls {
        position: absolute !important;
        top: 0 !important;
        bottom: initial !important;
        height: 100% !important;
        padding: 0 !important;
      }

      .controls-wrapper {
        width: 100% !important;
        height: 100% !important;
      }

      .inner-wrapper {
        width: 100% !important;
        height: 100% !important;
      }

      .control-header {
        position: absolute !important;
        top: 50% !important;
        left: 5vw !important;
        transform: translateY(-50%) !important;
      }

      .control-header img {
        display: none !important;

        @media (min-width: 992px) {
          width: 500px !important;
        }

        @media (min-width: 1200px) {
          width: 500px !important;
        }

        @media (min-width: 1400px) {
          width: 550px !important;
        }

        @media (min-width: 1600px) {
          width: 550px !important;
        }
      }

      .control-buttons {
        position: absolute !important;
        right: 5vw !important;
        bottom: min(56px, 5vh) !important;
      }
    }
  }

  //DISABLE THE LOGO
  :is(.video-thumbnail-logo-wrap, .control-header) img {
    display: none !important;
  }

  .ush-cinema-check-msg-wrap {
    display: none;
  }

  .load-more-cinemas__button .load-more-icon {
    color: var(--backgroundColor) !important;
  }

  .load-more-cinemas__button span:hover {
    /*color:  var(--backgroundColor);*/
    text-decoration-color: var(--backgroundColor) !important;

    text-decoration: underline;
  }

  ush-location-selector .location-input .p-autocomplete-input {
    border: 1px solid var(--backgroundColor) !important;
    background: black !important;
    color: white !important;
  }

  .panel-showtimes {
    background: black !important;
  }

  .showtime-cinema-wrapper.new-flow {
    .showtime-cinema .distance-literal {
      color: white !important;
    }
    .ush-cinema-name {
      color: white !important;
    }
    .ush-cinema-info fa-icon {
      color: white !important;
    }
    .ush-cinema-info .ush-cinema-address {
      color: white !important;
    }
  }
  .date-item.active {
    background: white !important;
    color: black !important;
  }
  .date-item:hover {
    color: black !important;
    background-color: white;
  }

  .showtime-cinema-wrapper.new-flow {
    background-color: #{$color4} !important;
  }
  .panel-showtimes {
    background-color: #{$color4} !important;
  }
  ush-ui-hour:hover {
    background-color: #fff !important;
    color: black !important;
  }

  ush-location-selector .ush-near-me-button {
    color: black !important;
  }
  ush-location-selector .ush-near-me-button:hover {
    --componentInCinemas-nearMetextBorder--hover: none;
    background-color: white !important;
    color: black !important;
  }

  feature-gallery-carousel {
    ush-arrow {
      .arrow-long {
        &:after,
        &:before {
          border-right: 2px solid white !important;
        }
        background: white !important;
      }
    }
  }

  .menu.brandBg .link-box {
    color: white !important;
  }

  .menu.brandBg .link-box,
  .unstoppable .menu.brandBg .link-box {
    color: black !important;
  }
  #vertical .menu-container .menu a {
    color: white !important;
  }
  #vertical .menu-container .menu a:hover {
    color: black !important;
  }

  #vertical .menu-container .menu nav a {
    color: black !important;
    &:hover {
      color: white !important;
    }
  }

  .p-dialog .p-dialog-header {
    background: transparent !important;
    color: black !important;
  }

  .p-dialog .p-dialog-content {
    background: transparent !important;
  }

  ush-modal-map {
    .p-dialog-header-icon {
      color: $color4 !important;
    }
  }

  ush-video {
    height: auto;
  }
  art-footer #footer-container ush-showtime-streaming-subscribe {
    .subscribe-form {
      background: black !important;
    }
    .subscribe-desc {
      color: white !important;
    }
  }

  app-go-to-top button#goToTop {
    background-color: white !important;
    color: black !important;
  }

  .p-dialog {
    .p-dialog-header,
    .p-dialog-content {
      background: white !important;
      color: black !important;
      padding: 0 1.5rem 2rem;
    }
    .p-dialog-header {
      padding: 1.5rem;
      padding-left: 70px;
      padding-right: 70px;
    }
    .p-button-label,
    .p-dialog-footer {
      background: white !important;
      color: black !important;
    }
  }
  .p-inputswitch .p-inputswitch-slider:before {
    background: #fff !important;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.25rem;
    margin-top: -0.625rem;
    border-radius: 50%;
    transition-duration: 0.2s;
  }

  p-dialog .p-dialog .p-dialog-content .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: black !important;
  }
  .p-button .p-button-icon-left {
    margin-right: 0.5rem;
  }

  art-footer {
    #footer-container {
      background-color: black;

      .subscribe-form {
        background-color: black !important;
      }

      .footer-menu {
        a {
          .border {
            background-color: var(--brandColor) !important;
          }
        }
      }
    }
  }

  .loading-container fa-icon {
    color: white !important;
  }

  @media (min-width: 576px) {
    --buttonFontSize: 18px;
  }

  @media (min-width: 992px) {
    #header-sm button {
      color: black !important;
    }
  }

  @media (max-width: 767px) {
    #header-sm {
      justify-content: end !important;
    }
    #header-sm button {
      color: black !important;
    }
    ush-location-selector .ush-near-me-button,
    .house-icon {
      color: black !important;
    }
    ush-location-selector .ush-near-me-button:hover,
    .house-icon:hover {
      --componentInCinemas-nearMetextBorder--hover: none;
      background-color: var(--buttonBackgroundHover) !important;
      color: white !important;
    }
  }
}
