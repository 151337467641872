// primary btn
@mixin button-primary() {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: var(--button-align-self, unset);
  transition: var(--buttonTransition);
  cursor: pointer;
  margin: var(--buttonMargin);
  border: var(--buttonBorder);
  border-radius: var(--borderRadius);
  background: var(--buttonBackground);
  padding: var(--buttonPaddingMobile);
  aspect-ratio: var(--button-aspect-ratio, unset);
  width: max-content;
  color: var(--buttonColor);
  font-weight: var(--buttonFontWeight);
  font-size: var(--buttonFontSizeMobile);
  text-align: center;
  text-transform: var(--buttonTextTransform);
  @media all and (min-width: $sm) {
    padding: var(--buttonPadding);
    font-size: var(--buttonFontSize);
  }
  &:hover,
  &:hover:enabled,
  &:focus,
  &:active {
    @include button-primary-hover();
    outline: none;
  }
}
// primary btn on hover or on focus
@mixin button-primary-hover() {
  border: var(--buttonBorderHover);
  background: var(--buttonBackgroundHover);
  color: var(--buttonColorHover);
}

// primary btn
@mixin button-secondary() {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--buttonTransition);
  cursor: pointer;
  margin: var(--buttonMargin);
  border: var(--button-secondary-border, var(--buttonBorder));
  border-radius: var(--borderRadius);
  background: var(--button-secondary-background, var(--backgroundColor));
  padding: var(--buttonPaddingMobile);
  aspect-ratio: var(--button-secondary-aspect-ratio, unset);
  width: max-content;
  color: var(--button-secondary-color, var(--brandColor));
  font-weight: var(--buttonFontWeight);
  font-size: var(--buttonFontSizeMobile);
  text-align: center;
  text-decoration: none;
  text-transform: var(--buttonTextTransform);

  @media all and (min-width: $sm) {
    padding: var(--buttonPadding);
    font-size: var(--buttonFontSize);
  }

  &:is(:hover, :hover:enabled, :focus, :active) {
    @include button-secondary-hover();
    outline: none;
  }
}
// primary btn on hover or on focus
@mixin button-secondary-hover() {
  border: var(--button-secondary-hover-border, unset);
  border-color: var(--button-secondary-hover-border-color, var(--backgroundColor));
  background: var(--button-secondary-hover-background, var(--brandColor));
  color: var(--button-secondary-hover-color, var(--backgroundColor));
}
