@mixin font-family-bundle($name, $font, $fontbold) {
  @font-face {
    font-family: $name;
    font-display: swap;
    src: url($font) + ' ' + getFormat($font);
    font-weight: normal;
  }
  @font-face {
    font-family: $name;
    font-display: swap;
    src: url($fontbold) + ' ' + getFormat($fontbold);
    font-weight: bold;
  }
}

@function getFormat($src) {
  @if (str-index($src, '.woff2')) {
    @return 'format("woff2")';
  }
  @if (str-index($src, '.woff')) {
    @return 'format("woff")';
  }
  @if (str-index($src, '.ttf')) {
    @return 'format("truetype")';
  }
  @if (str-index($src, '.otf')) {
    @return 'format("opentype")';
  }
  @if (str-index($src, '.eot')) {
    @return 'format("embedded-opentype")';
  }
  @return '';
}
